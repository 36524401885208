.elementToFadeInAndOut {

    -webkit-animation: fadeinout 1s infinite alternate;
    animation: fadeinout 1s infinite alternate;
}

@-webkit-keyframes fadeinout {
  0%,100% { opacity: 0; }
  50% { opacity: 1; }
}

@keyframes fadeinout {
  0%,100% { opacity: 0; }
  50% { opacity: 1; }
}